import { useState, useCallback } from "react";

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false);

  const withLoading = useCallback(async (action: () => Promise<void>) => {
    setIsLoading(true);
    try {
      await action(); // 비동기 작업 실행
    } catch (error) {
      console.error("Error in action:", error);
      throw error; // 에러를 다시 던져 호출자가 처리할 수 있도록 함
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, withLoading };
};

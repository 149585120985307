import { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { searchState } from "../../../state/search";
import { dataState } from "../../../state/data";
import { getManagerData } from "../../../api/manager/getManagerData";
import ManagerOverallData from "./ManagerOverallData";
import EventHistory from "./ManagerEventHistory";
import { getMostPointsPlayer } from "../../../api/manager/getMostPointsPlayer";

interface ManagerDetailProps {
  activeTab: string;
}

const ManagerDetail: React.FC<ManagerDetailProps> = ({ activeTab }) => {
  const search = useRecoilValue(searchState);
  const [manager, setManager] = useState<any | null>(null);
  const [mostPointsPlayer, setMostPointsPlayer] = useState<any | null>(null);

  const data = useRecoilValue(dataState);

  const fetchManagerData = async () => {
    try {
      const response = await getManagerData(search.managerId, data.currentRound);
      setManager(response);
    } catch (error) {
      console.error("Error fetching manager data:", error);
    }
  };

  const fetchPlayerPointsData = async () => {
    try {
      const response = await getMostPointsPlayer(search.managerId);
      console.log(response);
      setMostPointsPlayer(response);
    } catch (error) {
      console.error("Error fetching manager data:", error);
    }
  };

  useEffect(() => {
    if (search.managerId || search.managerName !== "") {
      fetchManagerData();
      fetchPlayerPointsData();
    }
  }, [search]);

  return (
    <Container>
      {activeTab === "overall" && <ManagerOverallData managerData={manager} mostPointsPlayerData={mostPointsPlayer} />}
      {activeTab === "eventHistory" && <EventHistory managerId={search.managerId} managerData={manager} />}
    </Container>
  );
};

export default ManagerDetail;

const Container = styled.div``;

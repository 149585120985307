import { useEffect, useState } from "react";
import styled from "styled-components";
import { getPlayerImgSrcById } from "../../../utils/getPlayerImgSrcById";
import { NumericText, fontSize } from "../../../assets/style/font";
import { Title } from "../../../assets/style/Title";
import colors from "../../../assets/colors/colors";
import PlayerModal from "../../../components/PlayerModal";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import { FaArrowCircleUp, FaArrowCircleDown } from "react-icons/fa";

const Transfer = () => {
  const [transferInData, setTransferInData] = useState([]);
  const [transferOutData, setTransferOutData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<any>(null);
  const [teamData, setTeamData] = useState<any>(null);
  const data = useRecoilValue(dataState);

  const collectData = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const transferInResponse = await fetch(`${serverUrl}/api/player/top-players/transfer-in`);
    const transferOutResponse = await fetch(`${serverUrl}/api/player/top-players/transfer-out`);

    setTransferInData((await transferInResponse.json()).slice(0, 3));
    setTransferOutData((await transferOutResponse.json()).slice(0, 3));
  };

  const handlePlayerClick = (player: any) => {
    const teamData = data.allTeamInfo.find((team: any) => team.id === player.team);
    setTeamData(teamData);
    setSelectedPlayer(player);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPlayer(null);
  };

  useEffect(() => {
    collectData();
  }, []);

  const getRankLabel = (index: number) => {
    switch (index) {
      case 0:
        return "1st";
      case 1:
        return "2nd";
      case 2:
        return "3rd";
      default:
        return "";
    }
  };

  const getPlayerInfo = (id: number) => {
    return data.allPlayerInfo.find((player: any) => player.id === id);
  };

  return (
    <TransferContainer>
      {isModalOpen && selectedPlayer && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          playerInfo={getPlayerInfo(selectedPlayer.id)}
          allTeamInfo={data.allTeamInfo}
          teamInfo={teamData}
          currentRound={data.currentRound}
        />
      )}
      <Section>
        <TransferHeader>
          <FaArrowCircleUp size={24} color={colors.baseColor} />
          <TransferTitle>Transfer In</TransferTitle>
        </TransferHeader>
        <PlayerList>
          {transferInData.map((player: any, index: number) => (
            <Player key={player.id} onClick={() => handlePlayerClick(player)}>
              <RankLabel>{getRankLabel(index)}</RankLabel>
              <PlayerImg src={getPlayerImgSrcById(player.code)} alt={player.web_name} />
              <PlayerName>{player.web_name}</PlayerName>
              <PlayerPoints>{player.transfers_in_event}</PlayerPoints>
            </Player>
          ))}
        </PlayerList>
      </Section>

      <Divider />

      <Section>
        <TransferHeader>
          <FaArrowCircleDown size={24} color={colors.baseColor} />
          <TransferTitle>Transfer Out</TransferTitle>
        </TransferHeader>
        <PlayerList>
          {transferOutData.map((player: any, index: number) => (
            <Player key={player.id} onClick={() => handlePlayerClick(player)}>
              <RankLabel>{getRankLabel(index)}</RankLabel>
              <PlayerImg src={getPlayerImgSrcById(player.code)} alt={player.web_name} />
              <PlayerName>{player.web_name}</PlayerName>
              <PlayerPoints>{player.transfers_out_event}</PlayerPoints>
            </Player>
          ))}
        </PlayerList>
      </Section>
    </TransferContainer>
  );
};

const TransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0.5rem;
  gap: 0.5rem;
  width: 100%;
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TransferHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 1440px) {
    margin-left: 0.5rem;
  }
`;

const TransferTitle = styled(Title)`
  text-align: center;
  color: ${colors.textBlack};
  @media (max-width: 1440px) {
    font-size: ${fontSize.media_small.medium};
    margin-left: 0.5rem;
  }
`;

const PlayerList = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.line};
  margin: 0.5rem 0;

  @media (max-width: 1440px) {
    width: 90%;
    margin: 0.5rem auto;
  }
`;

const Player = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.textBlack};
  text-align: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 0rem 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
`;

const PlayerImg = styled.img`
  width: 4rem;
  border-radius: 50%;

  @media (max-width: 500px) {
    width: 3rem;
  }
`;

const RankLabel = styled.div`
  font-size: ${fontSize.media_large.small};
  font-weight: bold;
  color: ${colors.baseColor};
  margin-bottom: 0.3rem;
`;

const PlayerName = styled.div`
  font-size: ${fontSize.media_large.small};
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const PlayerPoints = styled(NumericText)`
  font-size: 1rem;
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

export default Transfer;

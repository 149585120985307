import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../assets/colors/colors";
import { getPlayerImgSrcById } from "../utils/getPlayerImgSrcById";
import { getTeamNameByCode, getTeamNameById, getTeamSymbolById } from "../utils/getTeamNameById";
import teamColors from "../assets/colors/teamColors";
import { dataState } from "../state/data";
import { useRecoilValue } from "recoil";
import { fontSize } from "../assets/style/font";
import PlayerModal from "./PlayerModal";
import { getBackgroundColor } from "../utils/getBackgroundColor";

interface CardProps {
  teamColor: string;
  playerImage: string;
  eventPoints: number;
}

interface FixturesData {
  teamName: string;
  difficulty: number;
}

interface OverallProps {
  score: number;
}

const PlayerCard = ({ player, isCaptain }: any) => {
  const [teamColor, setTeamColor] = useState<string>("");
  const data = useRecoilValue(dataState);
  const [teamData, setTeamData] = useState<any>(null);

  const getTeamColor = () => {
    try {
      setTeamColor(
        teamColors[
          getTeamNameByCode(data.allTeamInfo, player.team_code).toLowerCase().replace(/\s/g, "").replace("'", "")
        ]
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getNextFixtures = () => {
    const playerData = data.allPlayerInfo.find((playerData: any) => player.id === playerData.id);
    if (!playerData) return [];

    const { fixtures, fixtures_difficulty } = playerData;

    const nextFixturesArray: Array<FixturesData> = [];
    for (let i = data.currentRound; i < data.currentRound + 3; i++) {
      if (!fixtures[i]) break;

      for (let j = 0; j < fixtures[i].length; j++) {
        if (fixtures[i][j]) {
          nextFixturesArray.push({
            teamName: getTeamSymbolById(data.allTeamInfo, fixtures[i][j]),
            difficulty: fixtures_difficulty[i][j],
          });
        }
      }
    }
    return nextFixturesArray;
  };

  useEffect(() => {
    getTeamColor();
  }, []);
  return (
    <Card teamColor={teamColor} playerImage={getPlayerImgSrcById(player.code)} eventPoints={player.event_points}>
      <Title>
        <Hightlight>
          <Overall score={player.overall_score}>
            <span>Score</span> {player.overall_score}
          </Overall>
          <PlayerName>
            {player.web_name} {isCaptain ? <span> C</span> : ""}
          </PlayerName>
        </Hightlight>
        <PlayerImage src={getPlayerImgSrcById(player.code)} alt="Player" />
      </Title>
      <CardContent>
        <Stats>
          <RoundStat>
            <strong>Round</strong>{" "}
            <strong>
              {" "}
              {isCaptain ? player.event_points * 2 : player.event_points} {isCaptain ? <span>(x2)</span> : ""}{" "}
            </strong>
          </RoundStat>
          <Stat>
            <strong>Total</strong> <span> {player.total_points}</span>
          </Stat>
          <Stat>
            <strong>Price</strong> <span> £{(player.now_cost / 10).toFixed(1)}m</span>
          </Stat>
          <Stat>
            <strong>Selected</strong>
            <span> {player.selected_by_percent}%</span>
          </Stat>
          <FixtureContainer>
            {getNextFixtures().map((fixture, idx) => (
              <FixtureBox key={idx} index={idx} difficulty={fixture.difficulty}>
                {player.event_points > 0 && idx === 0 ? player.event_points : fixture.teamName}
              </FixtureBox>
            ))}
          </FixtureContainer>
        </Stats>
        <MobileStats>
          <FixtureContainer>
            {getNextFixtures().map((fixture, idx) => (
              <FixtureBox key={idx} index={idx} difficulty={fixture.difficulty}>
                {player.event_points > 0 && idx === 0 ? player.event_points : fixture.teamName}
              </FixtureBox>
            ))}
          </FixtureContainer>
        </MobileStats>
      </CardContent>
    </Card>
  );
};

export default PlayerCard;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 1rem 1rem 1rem 1rem;

  cursor: pointer;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Hightlight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
`;

const Overall = styled.div<OverallProps>`
  font-size: ${fontSize.media_large.large};
  color: ${colors.textBlack};
  /* color: ${({ score }) => {
    if (score >= 100) {
      return colors.score0th; // Change to your desired color for high scores
    } else if (score >= 80) {
      return colors.score1st; // red
    } else if (score >= 70) {
      return colors.score2nd; // Change to your desired color for medium scores
    } else if (score >= 60) {
      return colors.score3rd; // Change to your desired color for medium scores
    } else {
      return colors.textBlack; // Change to your desired color for low scores
    }
  }}; */

  span {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }

  span {
    font-size: ${fontSize.media_large.small};
    color: ${colors.textBlack};

    @media (max-width: 768px) {
      font-size: ${fontSize.media_small.small};
    }
  }
`;

const PlayerName = styled.div`
  font-size: ${fontSize.media_large.small};
  font-weight: bold;
  text-align: left;
  color: ${colors.textBlack};

  span {
    font-size: ${fontSize.media_large.small};
    color: ${colors.textWhite};
    background-color: ${colors.textBlack};
    border-radius: 0.5rem;
    padding: 0.05rem 0.1rem;
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};

    span {
      font-size: ${fontSize.media_small.small};
    }
  }
`;

const PlayerImage = styled.img`
  display: block;
  width: 45%;
  object-fit: cover;
  z-index: 2;
  border-radius: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardContent = styled.div`
  text-align: center;
  width: 100%;
`;

const Stats = styled.div`
  font-weight: bold;
  font-size: ${fontSize.media_large.small};
  color: ${colors.textBlack};

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileStats = styled.div`
  display: none;
  font-weight: bold;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Stat = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  strong {
    text-align: left;
  }

  span {
    text-align: right;
  }

  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    font-size: ${fontSize.media_small.small};
  }
`;

const RoundStat = styled(Stat)`
  font-size: ${fontSize.media_large.medium};

  strong {
    font-weight: bold;
  }
`;

const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  background: ${({ eventPoints }) => {
    if (eventPoints >= 15) {
      return `linear-gradient(135deg, ${colors.score0th} 50%, rgba(255, 255, 255, 2) 100%)`;
    } else if (eventPoints >= 10) {
      return `linear-gradient(135deg, ${colors.score1st} 0%, rgba(255, 255, 255, 0.9) 100%)`;
    } else if (eventPoints >= 5) {
      return `linear-gradient(135deg, ${colors.score2nd} 0%, rgba(255, 255, 255, 0.9) 100%)`;
    } else if (eventPoints > 0) {
      return `linear-gradient(135deg, ${colors.score3rd} 0%, rgba(255, 255, 255, 0.9) 100%)`;
    } else {
      return `linear-gradient(135deg, ${colors.score4th} 0%, rgba(255, 255, 255, 0.5) 100%)`;
    }
  }};
  /* background: ${({ teamColor }) => `linear-gradient(135deg, ${teamColor} 0%, rgba(255, 255, 255, 0.9) 100%)`}; */

  border-radius: 1rem;
  width: 8rem;
  padding: 0.5rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 3rem;
    padding: 0.3rem;
    justify-content: space-between;
  }
`;

const FixtureContainer = styled.div`
  display: flex;
  gap: 0.1rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const FixtureBox = styled.div<{ index: number; difficulty: number }>`
  padding: 0.2rem 0.4rem;
  border-radius: 8px;
  background-color: ${({ difficulty }) => getBackgroundColor(difficulty)};
  color: ${colors.textWhite};
  font-size: ${fontSize.media_small.small};
  font-weight: bold;
  text-align: center;

  border: ${({ index }) => (index === 0 ? `2px solid ${colors.borderColor}` : "none")};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.1rem 0.2rem;
    width: 2rem;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import colors from "../../assets/colors/colors";
import Banner from "./components/Banner";
import BestPlayer from "./components/BestPlayer";
import LiveMatches from "./components/LiveMatches";
import InnerFixtureData from "./components/InnerFixtureData";
import InnerRoundFixtureData from "./components/InnerRoundFixturesData";
import Container from "../../components/common/Container";
import Transfer from "./components/Transfer";
import { HomeMetas } from "../../metadatas/metadatas";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataState } from "../../state/data";
import BoxContainer from "../../components/common/BoxContainer";
import TopPointsBox from "./components/TopPointsBox";
import InfoBox from "../../components/common/InfoBox";
import MiniLeagueNewEntries from "../../components/MiniLeagueNewEntries";
import { fontSize } from "../../assets/style/font";
import { getCommonData, getCurrentRound } from "../../utils/getCommonData";
import MiniLeagueSection from "./components/MiniLeagueSection";
import BestPlayerAndTransfer from "./components/BestPlayerAndTransfer";

interface HomeProps {
  playerInfo: any;
  allTeamInfo: any;
}

const Home: React.FC<HomeProps> = ({ playerInfo, allTeamInfo }: any) => {
  const [data, setData] = useRecoilState(dataState);
  const [fixturesDifficultyScore, setFixturesDifficultyScore] = useState([] as any);
  const [roundFixtures, setRoundFixtures] = useState([]);
  const [nextRoundFixtures, setNextRoundFixtures] = useState([]);
  const [menu, setMenu] = useState(0);

  const getData = async () => {
    const commonData: any = await getCommonData();
    setData({
      ...data,
      currentRound: parseInt(commonData.currentRound),
      loaded: true,
      allTeamInfo: commonData.teamInfo,
      allPlayerInfo: commonData.allPlayerInfo,
    });
  };

  const getFixturesData = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const roundFixtures = await axios.get(`${serverUrl}/api/team/round-fixtures/current`);
    const nextRoundFixtures = await axios.get(`${serverUrl}/api/team/round-fixtures/next`);
    const currentRound: number = data.currentRound;
    let fixturesDifficultyScore = [];
    for (let i = 1; i <= 20; i++) {
      fixturesDifficultyScore.push(0);
    }
    for (let i = 0; i < allTeamInfo.length; i++) {
      const teamFixtures = allTeamInfo[i].fixtures;
      let points = 0;
      let lastIndex = currentRound + 6 > teamFixtures.length ? teamFixtures.length : currentRound + 6;
      for (let j = currentRound + 1; j < lastIndex; j++) {
        points += allTeamInfo[i].fixtures_difficulty[j][0]
          ? 10 / parseInt(allTeamInfo[i].fixtures_difficulty[j][0])
          : 0;
        points += allTeamInfo[i].fixtures_difficulty[j][1]
          ? 10 / parseInt(allTeamInfo[i].fixtures_difficulty[j][1])
          : 0;
      }
      fixturesDifficultyScore[allTeamInfo[i].id] = points;
    }

    setFixturesDifficultyScore(fixturesDifficultyScore);
    setRoundFixtures(roundFixtures.data);
    setNextRoundFixtures(nextRoundFixtures.data);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (playerInfo && allTeamInfo) {
      getFixturesData();
    }
  }, [playerInfo, allTeamInfo]);

  // useEffect(() => {
  //   getTopData().then(() => {
  //     setIsLoading(false);
  //   });
  // }, [position]);

  if (data.currentRound === 0) {
    getData();
    return <HomeContainer>Loading Data</HomeContainer>;
  }

  return (
    <HomeContainer>
      <HomeMetas />
      <SectionRow>
        {/* <BestPlayer allTeamInfo={allTeamInfo} currentRound={data.currentRound} />
        <Transfer /> */}
        <BestPlayerAndTransfer allTeamInfo={allTeamInfo} currentRound={data.currentRound} />
      </SectionRow>

      <Section>
        <MiniLeagueSection />
        {/* <MiniLeagueRanking /> */}
        <MiniLeagueNewEntries />
      </Section>

      <PlayerDataContainer>
        <Header>
          <MenuItem active={menu === 0} onClick={() => setMenu(0)}>
            Round Top
          </MenuItem>
          <MenuItem active={menu === 1} onClick={() => setMenu(1)}>
            Overall Top
          </MenuItem>
          <MenuItem active={menu === 4} onClick={() => setMenu(4)}>
            xGoals + Assists
          </MenuItem>
          <MenuItem active={menu === 2} onClick={() => setMenu(2)}>
            Fixtures Difficulty
          </MenuItem>
          <MenuItem active={menu === 3} onClick={() => setMenu(3)}>
            Round Fixtures
          </MenuItem>
        </Header>

        {(menu === 0 || menu === 1 || menu === 4) && (
          <BoxContainer>
            {menu === 0 && (
              <>
                <TopPointsBox
                  title="Round Top Points"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="event_point"
                />
                <TopPointsBox
                  title="5 Games Top Points"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="recent_point"
                />
              </>
            )}
            {menu === 1 && (
              <>
                <TopPointsBox
                  title="Most Points"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="total_point"
                />
                <TopPointsBox
                  title="Top Scorer"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="goals"
                />
                <TopPointsBox
                  title="Most Assists"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="assists"
                />
                <TopPointsBox
                  title="Top Bonus"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="bonus"
                />
                <TopPointsBox
                  title="Top Selection (%)"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="selection"
                />
              </>
            )}
            {menu === 4 && (
              <>
                <TopPointsBox
                  title="xGoals"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="expected_goals"
                />
                <TopPointsBox
                  title="xAssists"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="expected_assists"
                />
                <TopPointsBox
                  title="xGoal Involvements"
                  allTeamInfo={allTeamInfo}
                  currentRound={data.currentRound}
                  option="expected_goal_involvements"
                />
              </>
            )}
          </BoxContainer>
        )}

        {menu === 2 && (
          <InnerFixtureData
            teamInfo={allTeamInfo}
            currentRound={parseInt(data.currentRound) + 1}
            fixturesDifficultyScore={fixturesDifficultyScore}
          />
        )}
        {menu === 3 && (
          <InnerRoundFixtureData
            roundFixtures={roundFixtures}
            teamInfo={allTeamInfo}
            currentRound={parseInt(data.currentRound)}
            fixturesDifficultyScore={fixturesDifficultyScore}
          />
        )}
      </PlayerDataContainer>
      <SectionRow>
        <Banner />
        <LiveMatches
          fixturesData={roundFixtures}
          nextFixturesData={nextRoundFixtures}
          teamData={allTeamInfo}
          currentRound={data.currentRound}
        />
      </SectionRow>

      {/* <Quiz /> */}
      {/* <LiveMatches
        fixturesData={roundFixtures}
        nextFixturesData={nextRoundFixtures}
        teamData={allTeamInfo}
        currentRound={data.currentRound}
      /> */}
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled(Container)`
  max-width: 1680px;
  @media (max-width: 768px) {
    padding: 0rem;
    width: 100%;
  }
`;

const PlayerDataContainer = styled(InfoBox)`
  width: 80%;
  margin: 2rem auto;
  padding: 0rem;
  @media (max-width: 1440px) {
    width: 100%;
    padding: 1rem 0rem;
    margin: 0rem auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-around; /* Equal space around each menu item */
  text-align: center;
  margin: 0.5rem auto;
  padding: 0.5rem;
  border-radius: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 0rem;
  }
`;

const MenuItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props.active ? colors.baseText : colors.buttonText)};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    color: ${colors.baseText};
    background-color: ${colors.buttonHoverBackground}; /* Light grey background on hover */
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.2rem;
    flex: 1 1 100%; /* Ensure items take full width on small screens */
  }
`;

const Section = styled(InfoBox)`
  border-radius: 1rem; // Rounded corners
  text-align: center; // Center-align the text
  margin: 2rem auto; // Add space at the top and center horizontally
  display: flex;
  padding: 0rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  gap: 1rem;
  /* background-color: ${colors.baseBoxBackground}; // Light background color */

  @media (max-width: 1440px) {
    flex-direction: column;
    width: 100%;
    padding: 1rem 0rem; // Add padding for spacing
    margin: 0rem auto; // Add space at the top and center horizontally
  }
`;

const SectionRow = styled(Section)`
  flex-direction: row;
  gap: 1rem;
  background-color: transparent;

  @media (max-width: 1440px) {
    flex-direction: column;
    width: 100%;
    padding: 1rem 0rem; // Add padding for spacing
    margin: 0rem auto; // Add space at the top and center horizontally
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    margin-left: 0rem;
  }
`;

import React from "react";
import ReactApexChart from "react-apexcharts";
import colors from "../assets/colors/colors";

interface PlayerFixtureGraphProps {
  historyPoints: number[];
}

const PlayerFixtureGraph: React.FC<PlayerFixtureGraphProps> = ({ historyPoints = [] }) => {
  const getColorForPoint = (point: number): string => {
    if (point >= 15) return colors.score0th; // Blue for 15-19
    if (point >= 10) return colors.score1st; // Orange for 10-14
    if (point >= 5) return colors.score3rd2; // Red for 5-9
    return colors.score4th; // Gray for 0-4
  };

  const barColors = historyPoints.map((point) => getColorForPoint(point));

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: historyPoints.map((_, index) => `GW${index + 1}`),
      labels: {
        rotate: -45,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Points",
      },
      min: 0,
    },
    plotOptions: {
      bar: {
        distributed: true, // Enable distributed bars for custom colors
      },
    },
    colors: barColors,
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      show: false, // Explicitly hide the legend
    },
    responsive: [
      {
        breakpoint: 768, // Apply settings for screens smaller than 768px
        options: {
          xaxis: {
            categories: historyPoints.map((_, index) => `${index + 1}`), // Remove "GW" prefix for mobile
            tickAmount: Math.ceil(historyPoints.length / 3), // Display fewer labels
            labels: {
              rotate: 0, // No rotation for mobile
              style: {
                fontSize: "10px",
              },
            },
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Points",
      data: historyPoints,
    },
  ];

  return (
    <div style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}>
      <ReactApexChart options={options} series={series} type="bar" height={200} />
    </div>
  );
};

export default PlayerFixtureGraph;

import axios from "axios";

export const getMostPointsPlayer = async (managerId: string) => {
  try {
    const serverUrl = process.env.REACT_APP_NEST_SERVER_ADDRESS;
    const url: string = `${serverUrl}/api/v2/manager/most-points-player`;
    const response = await axios.post(url, {
      managerId: managerId,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching data from FPL:", error);
  }
};

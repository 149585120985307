import React, { useState } from "react";
import styled from "styled-components";
import PlayerCard from "../../../components/PlayerCard";
import PlayerModal from "../../../components/PlayerModal";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";

const ManagerSquad = ({ squad, bench, captain, managerData }: any) => {
  const data = useRecoilValue(dataState);
  const [teamData, setTeamData] = useState<any>(null);
  const [playerData, setPlayerData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const goalkeepers = squad.filter((player: any) => player.element_type === 1);
  const defenders = squad.filter((player: any) => player.element_type === 2);
  const midfielders = squad.filter((player: any) => player.element_type === 3);
  const forwards = squad.filter((player: any) => player.element_type === 4);

  const eventTotalPoints = squad.reduce((acc: number, player: any) => {
    return player.id === captain ? acc + 2 * player.event_points : acc + player.event_points;
  }, 0);

  const benchTotalPoints = bench.reduce((acc: number, player: any) => acc + player.event_points, 0);

  const squadScore = squad.reduce((acc: number, player: any) => acc + parseFloat(player.overall_score), 0);

  const getTierClass = (rank: number) => {
    if (rank <= 100) return "tier-master";
    if (rank <= 50000) return "tier-diamond";
    if (rank <= 200000) return "tier-platinum";
    if (rank <= 2000000) return "tier-gold";
    if (rank <= 5000000) return "tier-silver";
    return "tier-bronze";
  };

  const getTierName = (rank: number) => {
    if (rank <= 100) return "Master";
    if (rank <= 50000) return "Diamond";
    if (rank <= 200000) return "Platinum";
    if (rank <= 2000000) return "Gold";
    if (rank <= 5000000) return "Silver";
    return "Bronze";
  };

  const handleOpenModal = (player: any) => {
    const teamData = data.allTeamInfo.find((team: any) => team.id === player.team);
    setTeamData(teamData);
    setPlayerData(player);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const getPlayerInfo = (id: number) => {
    return data.allPlayerInfo.find((player: any) => player.id === id);
  };

  return (
    <Container>
      <FormationContainer>
        <StatsContainer>
          <StatBox>
            <StatCard>
              <StatTitle>Total Points</StatTitle>
              <StatValue>{eventTotalPoints}</StatValue>
            </StatCard>
            <StatCard>
              <StatTitle>Universe Score</StatTitle>
              <StatValue>{squadScore.toFixed(0)}</StatValue>
            </StatCard>
          </StatBox>
          <StatCard className={getTierClass(managerData.summary_overall_rank)}>
            <StatTitle>
              Overall Rank
              <Tooltip>
                ?
                <TooltipContent>
                  <p>
                    <strong>Master:</strong> Top 100
                  </p>
                  <p>
                    <strong>Diamond:</strong> Top 50,000
                  </p>
                  <p>
                    <strong>Platinum:</strong> Top 200,000
                  </p>
                  <p>
                    <strong>Gold:</strong> Top 2,000,000
                  </p>
                  <p>
                    <strong>Silver:</strong> Top 5,000,000
                  </p>
                  <p>
                    <strong>Bronze:</strong> Others
                  </p>
                </TooltipContent>
              </Tooltip>
            </StatTitle>
            <StatValue>
              {managerData.summary_overall_rank} | {getTierName(managerData.summary_overall_rank)}
            </StatValue>
          </StatCard>
        </StatsContainer>

        <Row>
          {goalkeepers.map((player: any) => (
            <CardContainer onClick={() => handleOpenModal(player)}>
              <PlayerCard key={player.id} player={player} isCaptain={player.id === captain} />
            </CardContainer>
          ))}
        </Row>
        <Row>
          {defenders.map((player: any) => (
            <CardContainer onClick={() => handleOpenModal(player)}>
              <PlayerCard key={player.id} player={player} isCaptain={player.id === captain} />
            </CardContainer>
          ))}
        </Row>
        <Row>
          {midfielders.map((player: any) => (
            <CardContainer onClick={() => handleOpenModal(player)}>
              <PlayerCard key={player.id} player={player} isCaptain={player.id === captain} />
            </CardContainer>
          ))}
        </Row>
        <Row>
          {forwards.map((player: any) => (
            <CardContainer onClick={() => handleOpenModal(player)}>
              <PlayerCard key={player.id} player={player} isCaptain={player.id === captain} />
            </CardContainer>
          ))}
        </Row>

        <BenchContainer>
          <BenchText>
            <StatTitle>Bench Points</StatTitle>
            <StatValue>{benchTotalPoints}</StatValue>
          </BenchText>
          <Row>
            {bench.map((player: any) => (
              <CardContainer onClick={() => handleOpenModal(player)}>
                <PlayerCard key={player.id} player={player} />
              </CardContainer>
            ))}
          </Row>
        </BenchContainer>
      </FormationContainer>

      {isModalOpen && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          playerInfo={getPlayerInfo(playerData.id)}
          teamInfo={teamData}
          allTeamInfo={data.allTeamInfo}
          currentRound={data.currentRound}
        />
      )}
    </Container>
  );
};

export default ManagerSquad;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;

  @media (max-width: 1600px) {
    width: 100%;
  }
`;

const FormationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(0, 128, 0, 0.6), rgba(50, 205, 50, 0.6));
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (max-width: 1600px) {
    padding: 0rem;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    font-size: ${fontSize.media_small.small};
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StatBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0rem;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.infoBoxBackground};
  padding: 1rem 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &.tier-master {
    background: linear-gradient(135deg, purple, violet);
    color: white;
  }

  &.tier-diamond {
    background: linear-gradient(135deg, skyblue, lightblue);
    color: white;
  }

  &.tier-platinum {
    background: linear-gradient(135deg, #24c976, gray);
    color: black;
  }

  &.tier-gold {
    background: linear-gradient(135deg, gold, yellow);
    color: black;
  }

  &.tier-silver {
    background: linear-gradient(135deg, lightgray, white);
    color: black;
  }

  &.tier-bronze {
    background: linear-gradient(135deg, peru, goldenrod);
    color: black;
  }

  /* &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  } */

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
  }
`;

const StatTitle = styled.div`
  font-size: ${fontSize.media_large.medium};
  color: ${colors.textBlack};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const StatValue = styled.div`
  font-size: ${fontSize.media_large.large};
  font-weight: bold;
  color: ${colors.textBlack};
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  & > * {
    margin: 0 5px;
  }
`;

const BenchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  background: ${colors.infoBoxBackground};
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  @media (max-width: 1600px) {
    padding: 0.5rem;
  }
`;

const BenchText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${colors.textBlack};
  font-size: ${fontSize.media_large.medium};
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const Tooltip = styled.span`
  margin-left: 0.5rem;
  cursor: pointer;
  position: relative;
  font-size: ${fontSize.media_large.medium};
  background: ${colors.infoBoxBackground};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textBlack};
  font-weight: bold;
  white-space: nowrap;

  &:hover > span {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipContent = styled.span`
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  background-color: ${colors.infoBoxBackground};
  color: ${colors.textBlack};
  text-align: left;
  border-radius: 8px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
`;

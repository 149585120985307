import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { dataState } from "../state/data";
import { getCommonData } from "../utils/getCommonData";

export const useInitializeData = () => {
  const [data, setData] = useRecoilState(dataState);
  const [teamInfo, setTeamInfo] = useState<any | null>(null);
  const [playerInfo, setPlayerInfo] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const initializeData = async () => {
    const commonData: any = await getCommonData();
    setTeamInfo(commonData.teamInfo);
    setPlayerInfo(commonData.allPlayerInfo);
    setData({
      ...data,
      currentRound: parseInt(commonData.currentRound),
      loaded: true,
      allTeamInfo: commonData.teamInfo,
      allPlayerInfo: commonData.allPlayerInfo,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    initializeData();
  }, []);

  return { teamInfo, playerInfo, isLoading, data };
};

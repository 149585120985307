import React, { createContext, useContext } from "react";
import { useInitializeData } from "../hooks/useInitializeData";
const DataContext = createContext<any>(null);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { teamInfo, playerInfo, isLoading, data } = useInitializeData();

  return <DataContext.Provider value={{ teamInfo, playerInfo, isLoading, data }}>{children}</DataContext.Provider>;
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

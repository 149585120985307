import { useEffect, useState } from "react";
import styled from "styled-components";
import axios, { all } from "axios";
import ReactModal from "react-modal";
import colors from "../../assets/colors/colors";
import { getTeamLogoById } from "../../utils/getTeamLogoById";
import PlayerModal from "../../components/PlayerModal";
import PlayerInfoBox from "./components/PlayerInfoBox";
import Container from "../../components/common/Container";
import { useRecoilState, useRecoilValue } from "recoil";
import { optionState } from "../../state/option";
import { getPlayerImgSrcById } from "../../utils/getPlayerImgSrcById";
import { dataState } from "../../state/data";
import HeaderComponent from "../../components/Header";
import { PageContainer } from "../../components/common/PageContainer";
import { fontSize } from "../../assets/style/font";

ReactModal.setAppElement("#root"); // Assuming your root element has the ID 'root'

interface AnswerDataProps {
  rank?: number;
}
interface AnswerComponentProps {
  width?: string;
  mobileWidth?: string;
  questionIndex?: number;
  display?: string;
  headerIndex?: number;
}

const questionLists = [
  "BEST PICKS",
  "BEST Differentials",
  "Top Transfer In",
  "Top Transfer Out",
  "Most underpriced Forward",
  "Most underpriced Midfielder",
  "Most underpriced Defender",
  "Most underpriced Goalkeeper",
];

const answerHeaders = [
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Score"],
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Score"],
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Transfers In"],
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Transfers Out"],
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Score"],
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Score"],
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Score"],
  ["Name", "Team", "Point", "Recent", "Fixtures", "Next", "Price", "Selected", "Score"],
];

const answerHeadersIndex = [
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
];

const mobileAnswerHeadersIndex = [
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
  ["25%", "10%", "5%", "10%", "10%", "10%", "10%", "10%", "10%"],
];

function Player({ playerInfo, allTeamInfo }: any) {
  const [playerFacts, setPlayerFacts] = useState<any[]>([]);
  // const [questionIndex, setQuestionIndex] = useState<number>(options.playerMenuOption ? options.playerMenuOption : 0);
  const questionIndex = useRecoilValue(optionState).playerMenuOption;
  console.log("questionIndex", questionIndex);
  const [answers, setAnswers] = useState<Array<Array<string>>>([[]]);
  const [teamInfo, setTeamInfo] = useState<any[]>([]);
  const globalData = useRecoilValue(dataState);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [sortColumn, setSortColumn] = useState(""); // Column name to sort

  const handleOpenPlayerModal = (data: any) => {
    let teamData: any;
    for (let i = 0; i < allTeamInfo.length; i++) {
      if (allTeamInfo[i].id === data.team) {
        teamData = allTeamInfo[i];
        break;
      }
    }
    setIsPlayerModalOpen(true);
    setPlayerData(data);
    setTeamInfo(teamData);
  };

  const handleClosePlayerModal = () => {
    setIsPlayerModalOpen(false);
  };

  const getAnswer = async (index: number) => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    setIsLoading(true);

    try {
      if (index < 4) {
        const endpoint = ["watchable", "differential", "transfer-in", "transfer-out"][index];
        const response = await axios.get(`${serverUrl}/api/player/top-players/${endpoint}`);
        setAnswers(response.data);
      } else {
        const positions = ["forward", "midfielder", "defender", "goalkeeper"];
        const positionIndex = index - 4;
        const response = await axios.get(`${serverUrl}/api/player/top-players/underpriced`, {
          params: { position: positions[positionIndex] },
        });
        setAnswers(response.data);
      }
    } catch (error) {
      console.error("Error fetching answers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const getAnswer = async () => {
  //   const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
  //   let answers: Array<any> = [];

  //   const watchableResponse: any = await axios.get(`${serverUrl}/api/player/top-players/watchable`);
  //   answers.push(watchableResponse.data);

  //   const differentialResponse: any = await axios.get(`${serverUrl}/api/player/top-players/differential`);
  //   answers.push(differentialResponse.data);

  //   const transferInResponse: any = await axios.get(`${serverUrl}/api/player/top-players/transfer-in`);
  //   answers.push(transferInResponse.data);

  //   const transferOutResponse: any = await axios.get(`${serverUrl}/api/player/top-players/transfer-out`);
  //   answers.push(transferOutResponse.data);

  //   const positions = ["forward", "midfielder", "defender", "goalkeeper"];
  //   for (
  //     let questionIndex = questionLists.indexOf(questionLists[4]);
  //     questionIndex < questionLists.indexOf(questionLists[7]) + 1;
  //     questionIndex++
  //   ) {
  //     const underPricedResponse: any = await axios.get(`${serverUrl}/api/player/top-players/underpriced`, {
  //       params: {
  //         position: positions[questionIndex - questionLists.indexOf(questionLists[4])],
  //       },
  //     });
  //     answers.push(underPricedResponse.data);
  //   }
  //   setAnswers(answers);
  // };

  const getPlayerFacts = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const response: any = await axios.get(`${serverUrl}/api/common/facts/players`);
    setPlayerFacts(response.data);
  };

  useEffect(() => {
    getAnswer(questionIndex);
    console.log("questionIndex", questionIndex);
  }, [questionIndex]);

  useEffect(() => {
    getPlayerFacts();
  }, []);

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const answerData = (data: any, index: number) => {
    // TODO
    const rank = index + 1; // Check if the player is in the top 3
    if (data.length === 0) return;

    const commonCode = (index: number) => {
      return (
        <>
          <AnswerComponent
            width={answerHeadersIndex[index][0]}
            mobileWidth={mobileAnswerHeadersIndex[index][0]}
            questionIndex={questionIndex}
          >
            {data.web_name}
          </AnswerComponent>
          <AnswerComponent
            width={answerHeadersIndex[index][1]}
            mobileWidth={mobileAnswerHeadersIndex[index][1]}
            questionIndex={questionIndex}
            display="none"
          >
            <TeamLogo src={getTeamLogoById(allTeamInfo, data.team, "id")} alt="logo"></TeamLogo>
          </AnswerComponent>
          <AnswerComponent
            width={answerHeadersIndex[index][2]}
            mobileWidth={mobileAnswerHeadersIndex[index][2]}
            questionIndex={questionIndex}
          >
            {data.total_points}
          </AnswerComponent>
          <AnswerComponent
            width={answerHeadersIndex[index][3]}
            mobileWidth={mobileAnswerHeadersIndex[index][3]}
            questionIndex={questionIndex}
          >
            {data.recent_average_points}
          </AnswerComponent>
          <AnswerComponent
            width={answerHeadersIndex[index][4]}
            mobileWidth={mobileAnswerHeadersIndex[index][4]}
            questionIndex={questionIndex}
          >
            {data.fixtures_difficulty_sum ? data.fixtures_difficulty_sum : "-"}
          </AnswerComponent>
          <AnswerComponent
            width={answerHeadersIndex[index][4]}
            mobileWidth={mobileAnswerHeadersIndex[index][4]}
            questionIndex={questionIndex}
          >
            {globalData.currentRound + 1 && data.fixtures[globalData.currentRound + 1][0] ? (
              <OpponentLogos key={index}>
                <OpponentLogoBox>
                  <OpponentLogo
                    src={getTeamLogoById(allTeamInfo, data.fixtures[globalData.currentRound + 1][0], "id")}
                    alt="logo"
                  />
                </OpponentLogoBox>
                {data.fixtures_difficulty[globalData.currentRound + 1][1] && (
                  <OpponentLogoBox>
                    <OpponentLogo src={getTeamLogoById(allTeamInfo, data.fixtures[index][1], "id")} alt="logo" />
                  </OpponentLogoBox>
                )}
              </OpponentLogos>
            ) : null}
          </AnswerComponent>
          <AnswerComponent
            width={answerHeadersIndex[index][5]}
            mobileWidth={mobileAnswerHeadersIndex[index][5]}
            questionIndex={questionIndex}
          >
            {data.now_cost ? data.now_cost / 10 : "-"}
          </AnswerComponent>
          <AnswerComponent
            width={answerHeadersIndex[index][6]}
            mobileWidth={mobileAnswerHeadersIndex[index][6]}
            questionIndex={questionIndex}
          >
            {data.now_cost ? data.selected_by_percent : "-"}%
          </AnswerComponent>
        </>
      );
    };

    if (
      questionIndex === questionLists.indexOf("BEST PICKS") ||
      questionIndex === questionLists.indexOf("BEST Differentials")
    ) {
      return (
        <AnswerData rank={rank} onClick={() => handleOpenPlayerModal(data)}>
          {
            <>
              {commonCode(0)}

              <AnswerComponent
                width={answerHeadersIndex[0][7]}
                mobileWidth={mobileAnswerHeadersIndex[0][7]}
                questionIndex={questionIndex}
              >
                {questionIndex === questionLists.indexOf("BEST PICKS") ? (
                  <Overall value={data.overall_score}> {data.overall_score ? data.overall_score : "-"}</Overall>
                ) : (
                  <Overall value={data.differential_score}>
                    {" "}
                    {data.differential_score ? data.differential_score : "-"}
                  </Overall>
                )}
              </AnswerComponent>
            </>
          }
        </AnswerData>
      );
    } else if (
      questionIndex === questionLists.indexOf("Top Transfer In") ||
      questionIndex === questionLists.indexOf("Top Transfer Out")
    ) {
      return (
        <AnswerData onClick={() => handleOpenPlayerModal(data)}>
          {commonCode(2)}
          <AnswerComponent width={answerHeadersIndex[2][7]} mobileWidth={mobileAnswerHeadersIndex[2][7]}>
            {questionIndex === questionLists.indexOf("Top Transfer In")
              ? data.transfers_in_event
              : data.transfers_out_event}
          </AnswerComponent>
        </AnswerData>
      );
    } else if (
      questionIndex === questionLists.indexOf("Most underpriced Forward") ||
      questionIndex === questionLists.indexOf("Most underpriced Midfielder") ||
      questionIndex === questionLists.indexOf("Most underpriced Defender") ||
      questionIndex === questionLists.indexOf("Most underpriced Goalkeeper")
    ) {
      return (
        <AnswerData>
          {commonCode(4)}
          <AnswerComponent width={answerHeadersIndex[4][7]} mobileWidth={mobileAnswerHeadersIndex[4][7]}>
            {questionIndex === questionLists.indexOf("Top Transfer In")
              ? data.transfers_in_event
              : data.transfers_out_event}
          </AnswerComponent>
        </AnswerData>
      );
    }
  };

  const sortedAnswers = answers.slice().sort((a, b) => {
    if (sortColumn !== "") {
      const valueA = a[sortColumn as keyof typeof a];
      const valueB = b[sortColumn as keyof typeof b];
      if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
      if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <PlayerContainer>
      {isPlayerModalOpen && (
        <PlayerModal
          isOpen={isPlayerModalOpen}
          onClose={handleClosePlayerModal}
          playerInfo={playerData}
          allTeamInfo={allTeamInfo}
          teamInfo={teamInfo}
          currentRound={globalData.currentRound}
        />
      )}

      <PlayerInfoBox playerFacts={playerFacts} />

      <Answer>
        <Target>{questionLists[questionIndex]}</Target>
        <AnswerHeader>
          {answerHeaders[questionIndex].map((header, index) => (
            <AnswerComponent
              key={index}
              headerIndex={index}
              width={answerHeadersIndex[questionIndex][index]}
              mobileWidth={mobileAnswerHeadersIndex[questionIndex][index]}
              onClick={() => handleSort(header.toLowerCase())}
            >
              {header}
            </AnswerComponent>
          ))}
        </AnswerHeader>

        {sortedAnswers.map((answer: any, index: number) => answerData(answer, index))}
      </Answer>
    </PlayerContainer>
  );
}

export default Player;

const PlayerContainer = styled(PageContainer)``;

const Loading = styled.div`
  color: ${colors.baseText};
  font-size: 2rem;
  text-align: center;
  margin-top: 20%;
`;

const Target = styled.h2`
  margin-bottom: 20px;
`;

const Answer = styled.div`
  background-color: ${colors.answerBackground};
  color: white;
  padding: 0.8rem;
  flex: 0 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-size: 1.2rem;
  overflow-x: auto; // Enable horizontal scrolling

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.5rem;
  }
`;

const AnswerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-size: 1.4rem;
  overflow-x: auto;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    justify-content: flex-start; // Align items to start for better mobile view
  }
`;

const AnswerData = styled.div<AnswerDataProps>`
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 0.8rem;
  cursor: pointer;
  border-radius: 8px;
  background: ${(props) => {
    if (props.rank === 1) {
      return colors.gold;
    } else if (props.rank === 2) {
      return colors.silver;
    } else if (props.rank === 3) {
      return colors.bronze;
    } else {
      return "transparent";
    }
  }};
  color: ${(props) => {
    if (props.rank === 1 || props.rank === 2 || props.rank === 3) {
      return colors.textBlack;
    } else {
      return "white";
    }
  }};
  font: inherit;
  text-align: center;
  width: 100%;
  overflow-x: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    color: white;
    background-color: transparent;
    justify-content: flex-start; // Align items to start for better mobile view
  }
`;

const AnswerComponent = styled.div<AnswerComponentProps>`
  width: ${(props) => (props.width ? props.width : "25%")};
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: ${fontSize.media_large.medium};

  @media (max-width: 768px) {
    font-size: 0.7rem;
    width: ${(props) => (props.mobileWidth ? props.mobileWidth : "25%")};
    display: ${(props) => (props.mobileWidth === "0%" ? "none" : "flex")};
    margin: ${(props) =>
      props.headerIndex !== undefined && props.headerIndex % 2 === 0 ? "1rem 0 0 0" : "0 0 1rem 0"};
  }
`;

const Overall = styled.div<{ value: number }>`
  font-weight: bold;
  color: ${(props) => {
    if (props.value >= 100) {
      return colors.score1st;
    } else if (props.value >= 80) {
      return colors.score1st;
    } else if (props.value >= 70) {
      return colors.score2nd;
    } else if (props.value >= 60) {
      return colors.score3rd;
    } else if (props.value >= 50) {
      return colors.score4th;
    } else {
      return colors.score5th;
    }
  }};
`;

const TeamLogo = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin: auto;
  display: block;

  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const TopThree = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const HighlightedPlayer = styled(TopThree)<{ rank: number }>`
  background-color: ${(props) => {
    if (props.rank === 1) {
      return colors.gold;
    } else if (props.rank === 2) {
      return colors.silver;
    } else {
      return colors.bronze;
    }
  }};
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    background-color: ${colors.silver}; // 모바일에서는 조금 덜 강한 색상
    padding: 0.5rem;
  }
`;

const RankingBadge = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.textBlack};
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PlayerImage = styled.img`
  width: 5rem;
  border-radius: 50%;
  margin: auto;
  display: block;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const PlayerInfos = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${colors.textBlack};
`;

const PlayerName = styled.div`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: ${colors.textBlack};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PlayerPoints = styled.div`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;

  div {
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const OpponentLogos = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpponentLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OpponentLogo = styled(TeamLogo)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
